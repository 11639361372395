.menu-cover {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  background: rgba(230, 236, 255, 0.8);
  backdrop-filter: blur(24px);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.menu-item {
  text-align: center;
}

.menu-item.active {
  background: linear-gradient(90.47deg, #ff96d5 0%, #ffd390 100%);
}

.menu-text {
  font-size: 34px;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  line-height: 72px;
}

@media (min-width: 768px) {
  .menu-text {
    font-size: 48px;
    line-height: 96px;
  }
}
@media (min-width: 992px) {
  .menu-text {
    font-size: 64px;
    line-height: 128px;
  }
}
