body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: justify; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root a:focus {
  box-shadow: none;
}

#root {
  background-color: #e6ecff;
  min-height: 100vh;
}

.article .chakra-text {
  font-size: 16px;
  margin: 28px 0;
}
@media (min-width: 768px) {
  .article .chakra-text {
    font-size: 18px;
    margin: 32px 0;
  }
}
@media (min-width: 992px) {
  .article .chakra-text {
    font-size: 20px;
    margin: 36px 0;
  }
}

.article li {
  font-size: 16px;
  line-height: 28px;
}
@media (min-width: 768px) {
  .article li {
    font-size: 18px;
    line-height: 32px;
  }
}
@media (min-width: 992px) {
  .article li {
    font-size: 20px;
    line-height: 36px;
  }
}

.blockquote {
  font-size: 18px;
  font-weight: 800;
  position: relative;
  left: 10px;
}
/* .blockquote:before {
  position: absolute;
  color: #ffca9c;
  content: '\201C';
  font-family: serif;
  font-size: 40px;
  left: -22px;
  top: -12px;
} */
@media (min-width: 768px) {
  .blockquote {
    font-size: 20px;
  }
  /* .blockquote:before {
    font-size: 48px;
    left: -26px;
    top: -14px;
  } */
}
@media (min-width: 992px) {
  .blockquote {
    font-size: 21px;
    left: 0;
  }
  /* .blockquote:before {
    font-size: 56px;
    left: -36px;
    top: -18px;
  } */
}

#root .click-card {
  cursor: pointer;
  transition: box-shadow 0.5s;
  background: rgb(255, 255, 255, 1);
}
.click-card:hover {
  box-shadow: 0px 0px 20px rgb(0 0 0 / 25%);
  transition: box-shadow 0.2s;
}

#calendar .color-calendar {
  display: flex;
  align-items: center;
}
#calendar .color-calendar .calendar__arrow-inner {
  background: #ff98d3;
}
