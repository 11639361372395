.wrapper-lg {
  min-height: 640px;
}
.wrapper-lg .title-big {
  font-size: 82px;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  line-height: 108px;
  text-align: justify;
}

.wrapper-lg .title-big.last-right {
  text-align-last: right;
}

.wrapper-sm .title-big {
  font-size: 48px;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  line-height: 56px;
}

.wrapper-sm {
  min-height: 540px;
  margin-left: -4px;
  margin-right: -4px;
}
@media (min-width: 480px) {
  .wrapper-sm {
    margin-left: 10px;
    margin-right: 10px;
  }
}
